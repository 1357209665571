@import (reference) "./_var.less";
@import (reference) "./_buttons.less";
@import (reference) "./_links.less";

//Background colors
@bg-color-white: @color-white;
@bg-color-gray: @color-gray;
@bg-color-red: @color-red;
@bg-color-blue: @color-blue;
@bg-color-black: @color-black;

._white() {
  background-color: @bg-color-white;

  a, button:not(.slick-arrow) {
    &.primary-button {
      ._primary_button_white_gray_bg();
    }

    &.secondary-button {
      ._secondary_button_white_gray_bg();
    }

    &:not(.btn) {
      ._normal_link_white_gray_bg();
    }

    &.right-column-link,
    &.special-link {
      ._special_link_white_gray_bg();
    }
    &.list-link{
      text-decoration: none;
      font-weight: @weight-bold;
      position: relative;
      display: block;
      margin-right: 5px;

    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  div,
  ol li,
  ul li {
    color: @color-black;
  }

  ul li::before {
    color: @color-black;
  }

  ul{
    &.list-group{
      li
      {
        ._list_link_white_gray_bg();
      }
    }
  }

  li a {
    ._normal_link_white_gray_bg();
  }

  select {
    border-color: @color-railway-gray;

    &:focus { 
      border-color: @bg-color-black;
    }
  }

  .img i {
    color: @color-red;
  }

  .slick-dots-placeholder {
    .slick-dots {
      li.slick-active {
        button {
          &::before {
            color: @color-black;
          }
        }
      }

      li {
        button {
          &::before {
            color: rgba(157, 151, 149, 1);
          }

          &:focus {
            outline: 2px solid @color-black;
          }
        }
      }
    }
  }

  button.slick-arrow {

    &.slick-prev,
    &.slick-next {
      background-color: @color-yellow;

      &:focus {
        &::before {
          outline: 2px solid @color-black;
          outline-offset: 2px;
        }
      }

      &::before {
        color: @color-black;
      }
    }
  }
}

._gray() {
  background-color: @bg-color-gray;

  a, button:not(.slick-arrow) {
    &.primary-button {
      ._primary_button_white_gray_bg();
    }

    &.secondary-button {
      ._secondary_button_white_gray_bg();
    }

    &:not(.btn) {
      ._normal_link_white_gray_bg();
    }

    &.right-column-link,
    &.special-link {
      ._special_link_white_gray_bg();
    }
    &.list-link {
      text-decoration: none;
      font-weight: @weight-bold;
      position: relative;
      display: block;
      margin-right: 5px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  div,
  ol li,
  ul li {
    color: @color-black;
  }

  ul li::before {
    color: @color-black;
  }

  ul{
    &.list-group{
      li
      {
        ._list_link_white_gray_bg();
      }
    }
  }

  li a {
    ._normal_link_white_gray_bg();
  }

  select {
    border-color: @color-railway-gray;

    &:focus { 
      border-color: @bg-color-black;
    }
  }

  .img i {
    color: @color-red;
  }

  .slick-dots-placeholder {
    .slick-dots {
      li.slick-active {
        button {
          &::before {
            color: @color-black;
          }
        }
      }

      li {
        button {
          &::before {
            color: rgba(157, 151, 149, 1);
          }

          &:focus {
            outline: 2px solid @color-black;
          }
        }
      }
    }
  }

  button.slick-arrow {

    &.slick-prev,
    &.slick-next {
      background-color: @color-yellow;

      &:focus {
        &::before {
          outline: 2px solid @color-black;
          outline-offset: 2px;
        }
      }

      &::before {
        color: @color-black;
      }
    }
  }
}

._red() {
  background-color: @bg-color-red;

  a, button:not(.slick-arrow) {
    &.primary-button {
      ._primary_button_red_blue_black_bg();
    }

    &.secondary-button {
      ._secondary_button_red_blue_black_bg();
    }

    &:not(.btn) {
      ._normal_link_red_blue_black_bg();
    }

    &.right-column-link,
    &.special-link {
      ._special_link_red_blue_black_bg();
    }
    &.list-link {
      text-decoration: none;
      font-weight: @weight-bold;
      position: relative;
      display: block;
      margin-right: 5px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  div,
  ol li,
  ul li {
    color: @color-white;
  }

  ul li::before {
    color: @color-white;
  }

  ul{
    &.list-group{
      li
      {
        ._list_link_red_blue_black_bg();
      }
    }
  }

  li a {
    ._normal_link_red_blue_black_bg();
  }

  select {
    border-color: @color-white;

    &:focus {
      border-color: @bg-color-black;
    }
  }

  .img i {
    color: @color-black;
  }

  .slick-dots-placeholder {
    .slick-dots {
      li.slick-active {
        button {
          &::before {
            color: @color-black;
          }
        }
      }

      li {
        button {
          &::before {
            color: rgba(255, 255, 255, 0.5);
          }

          &:focus {
            outline: 2px solid @color-black;
          }
        }
      }
    }
  }

  button.slick-arrow {

    &.slick-prev,
    &.slick-next {
      background-color: @color-yellow;

      &:focus {
        &::before {
          outline: 2px solid @color-black;
          outline-offset: 2px;
        }
      }

      &::before {
        color: @color-black;
      }
    }
  }
}

._blue() {
  background-color: @bg-color-blue;

  a, button:not(.slick-arrow) {
    &.primary-button {
      ._primary_button_red_blue_black_bg();
    }

    &:not(.btn) {
      ._normal_link_red_blue_black_bg();
    }

    &.secondary-button {
      ._secondary_button_red_blue_black_bg();
    }

    &.right-column-link,
    &.special-link {
      ._special_link_red_blue_black_bg();
    }
    &.list-link {
      text-decoration: none;
      font-weight: @weight-bold;
      position: relative;
      display: block;
      margin-right: 5px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  div,
  ol li,
  ul li {
    color: @color-white;
  }

  ul li::before {
    color: @color-white;
  }

  ul{
    &.list-group{
      li
      {
        ._list_link_red_blue_black_bg();
      }
    }
  }

  li a {
    ._normal_link_red_blue_black_bg();
  }

  select {
    border-color: @color-railway-gray;

    &:focus { 
      border-color: @bg-color-white;
    }
  }

  .img i {
    color: @color-red;
  }

  .slick-dots-placeholder {
    .slick-dots {
      li.slick-active {
        button {
          &::before {
            color: @color-red;
          }
        }
      }

      li {
        button {
          &::before {
            color: rgba(255, 255, 255, 0.5);
          }

          &:focus {
            outline: 2px solid @color-red;
          }
        }
      }
    }
  }

  button.slick-arrow {

    &.slick-prev,
    &.slick-next {
      background-color: @color-yellow;

      &:focus {
        &::before {
          outline: 2px solid @color-white;
          outline-offset: 2px;
        }
      }

      &::before {
        color: @color-black;
      }
    }
  }
}

._black() {
  background-color: @bg-color-black;

  a, button:not(.slick-arrow) {
    &.primary-button {
      ._primary_button_red_blue_black_bg();
    }

    &:not(.btn) {
      ._normal_link_red_blue_black_bg();
    }

    &.secondary-button {
      ._secondary_button_red_blue_black_bg();
    }

    &.right-column-link,
    &.special-link {
      ._special_link_red_blue_black_bg();
    }
    &.list-link {
      text-decoration: none;
      font-weight: @weight-bold;
      position: relative;
      display: block;
      margin-right: 5px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  div,
  ol li,
  ul li {
    color: @color-white;
  }

  ul li::before {
    color: @color-black;
  }

  ul{
    &.list-group{
      li
      {
        ._list_link_red_blue_black_bg();
      }
    }
  }

  li a {
    ._normal_link_red_blue_black_bg();
  }

  select {
    border-color: @color-railway-gray;

    &:focus { 
      border-color: @bg-color-white;
    }
  }

  .img i {
    color: @color-red;
  }

  .slick-dots-placeholder {
    .slick-dots {
      li.slick-active {
        button {
          &::before {
            color: @color-red;
          }
        }
      }

      li {
        button {
          &::before {
            color: rgba(255, 255, 255, 0.5);
          }

          &:focus {
            outline: 2px solid @color-red;
          }
        }
      }
    }
  }

  button.slick-arrow {

    &.slick-prev,
    &.slick-next {
      background-color: @color-yellow;

      &:focus {
        &::before {
          outline: 2px solid @color-white;
          outline-offset: 2px;
        }
      }

      &::before {
        color: @color-black;
      }
    }
  }
}