@import (reference) "./_var.less";

@link-font-family: @primary-font-family;
@link-font-size: 16px;
@link-line-height: 150%;

//NORMAL LINKS

._normal_link() {
  font-family: @link-font-family;
  font-size: 16px;
  font-weight: @weight-regular;
  line-height: @link-line-height;
  text-decoration: underline;

  &:focus,
  &:focus-visible {
    outline-style: solid;
    outline-width: 2px;
  }
}

._normal_link_white_gray_bg() {
  ._normal_link();
  color: @color-red;

  &:hover {
    color: @color-dark-red;
  }

  &:focus,
  &:focus-visible {
    outline-color: @color-black;
  }
}

._normal_link_red_blue_black_bg() {
  ._normal_link();
  color: @color-yellow;

  &:hover {
    color: @color-white;
  }

  &:focus,
  &:focus-visible {
    outline-color: @color-white;
  }
}
//END OF NORMAL LINKS

//SPECIAL LINKS

._special_link() {
  font-family: @link-font-family;
  font-size: @link-font-size;
  line-height: @link-line-height;
  font-weight: @weight-bold;
  padding: 10px 0;
  border-bottom: 1px solid transparent;
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: none;
  }

  &:focus {
    border-bottom-color: transparent;
  }
}

._special_link_white_gray_bg() {
  ._special_link();
  color: @color-black;
  border-bottom-color: @color-red;

  &:hover {
    color: @color-red;
  }
}

._special_link_red_blue_black_bg() {
  ._special_link();
  color: @color-white;
  border-bottom-color: @color-yellow;

  &:hover {
    color: @color-yellow;
  }
}
//END OF SPECIAL LINKS

// Link List
._list_link() {
  font-family: @link-font-family;
  font-size: 16px;
  font-weight: @weight-bold;
  line-height: @link-line-height;
  text-decoration: none;
  position: relative;
  padding: 15px 0;
  display: block;
  &:hover {
    text-decoration: underline;
  }
  &::after {
    content: "\f0da";
    position: absolute;
    font-family: @font-awesome-family;
    font-weight: @fa-weight-solid;
    font-size: 18px;
    line-height: 110%;
    margin-left: 10px;
    top: 50%; /* Adjusted positioning to vertically center the icon */
    transform: translateY(-50%); /* Adjusted positioning to vertically center the icon */
    display:inline-block;
    right: 0;
  }
  

  &:focus,
  &:focus-visible {
    outline-style: solid;
    outline-width: 2px;
    border-top: 0;
  }
}

._list_link_white_gray_bg() {
  ._list_link();
  color: @color-black;
  border-bottom:1px solid @color-black-50;
  &:first-of-type {
    border-top: 1px solid @color-black-50;
  }
  &::after {
    color: @color-red;
  }
  &:hover {
    color: @color-black;
  }

  &:focus,
  &:focus-visible {
    outline-color: @color-black;
  }
}

._list_link_red_blue_black_bg() {
  ._list_link();
  color: @color-white;
  border-bottom: 1px solid @color-white-50;
  &:first-of-type {
    border-top: 1px solid @color-white-50;
  }
  &::after {
    color: @color-yellow;
  }

  &:focus,
  &:focus-visible {
    outline-color: @color-white;
  }
}
// End of Link List
