@import (reference) "./_var.less";

section, section div {
  .rich-text-content {
    h1, .h1 {
      a:not(.btn) {
        ._h1();
      }
    }

    h2, .h2 {
      a:not(.btn) {
        ._h2();
      }
    }

    h3, .h3 {
      a:not(.btn) {
        ._h3();
      }
    }

    h4, .h4 {
      a:not(.btn) {
        ._h4();
      }
    }

    h5, .h5 {
      a:not(.btn) {
        ._h5();
      }
    }

    h1, .h1, .h2, h2, .h3, h3, h4, .h4, .h5, h5 {
      font-weight: 400;
      margin-top: 30px; 

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child{
        margin-top: 0;
      }

      a:not(.btn) {
        font-weight: 400;
      }

      &.special-heading {
        a:not(.btn) {
          ._special-heading();
        }
      }
    }

    > p:not(:last-child) {
      margin-bottom: 15px;
    }

    ._ol_ul();
  }
}
