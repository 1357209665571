@import (reference) "_var.less";

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body,
html {
    background-color: @color-white;
    font-size: @font-size-base;
    color: @color-black;
    width: 100%;
    height: 100%;
    margin: 0;
}


body {
    font-family: @primary-font-family;
}

body, app-root { 
    min-height: 100vh; 
    display: flex;
    flex-direction: column;
}
main {
    flex: 1;
}

header {
	position: sticky;
    position: -webkit-sticky;
	top: 0; 
    min-height:90px;
    width: 100%;
    z-index: @header-content;
}

.footer {
    flex-shrink: 0;
}

.divider {
    padding: 0 45px;

    @media @phone-down {
        padding: 0 20px;
    }

    .line-break {
        margin: 0 auto;

        &:after {
            border-color: @color-black-50;
            height: 1px;
            border-top: 1px solid;
            content: '';
            display: block;
        }
    }
}

body > app-footer {
  position: sticky;
  top: 100vh;
}

img {
    max-width: 100%;
    height: auto;
}

hr {
    border: none;
    height: 1px; 
    background: #959595;
    margin: 40px 0;
}

#main-container {
    min-height: 100%;
    position: relative;
}

section {
    sup {
        font-size: 14px;
    }
}

.master-container {
    width: calc(100% - 20px);
    max-width: @container-width;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    @media @tablet-down {
        width: calc(100% - 20px);
        padding-left: 10px;
        padding-right: 10px;
    }
}

.tabs {
    li {
        margin-bottom: 0;
    }
}

div[role=link]:focus,
button:focus,
input:focus,
a.btn:focus {
    outline: 2px solid @color-black;
}

a:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

h1, .h1 {
  ._h1();

  &.special-heading {
    ._special-heading();
  }
}

h2, .h2 {
  ._h2();

  &.special-heading {
    ._special-heading();
  }
}

h3, .h3 {
  ._h3();

  &.special-heading {
    ._special-heading();
  }
}

h4, .h4 {
  ._h4();

  &.special-heading {
    ._special-heading();
  }
}

h5, .h5 {
  ._h5();

  &.special-heading {
    ._special-heading();
  }
}

p {
  ._p();
}
  
.quote {
  ._quote();
}
  
label {
  ._label_heading();
}

.hideChatButton {
  display: none !important;
}

//ReCaptcha Logo
.grecaptcha-badge {
  visibility: hidden;
}
