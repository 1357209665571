@import (reference) "./_var.less";
button.slick-arrow {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  z-index: @above-content;

  &:focus {
    outline: none;
  }

  &:before {
    font-family: @font-awesome-family;
    line-height: 40px;
    font-size: 20px;
    font-weight: 300;
    display: block;

    &:hover,
    &:focus {
      opacity: @hover-opacity;
      text-decoration: none;
      border-bottom: none;
    }
  }

  &.mobile-slick-arrow {
    display: inline-block !important;
  }

  &.slick-prev {
    &:before {
      content: @fa-chevron-left;
    }
  }

  &.slick-next {
    &:before {
      content: @fa-chevron-right;
    }
  }
}

@media @phone-down {
  button.slick-arrow {
    display: inline-block !important;

    &.mobile-slick-arrow {
      display: block !important;
      position: absolute;
      top: 20px;
    }
  }
}

.slick-loading .slick-list {
  background: none !important;
}

.slick-dots-placeholder {
  margin-top: 20px;
  .slick-dots {
    bottom: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    transform: unset;
    margin: 5px 0;

    li {
      &.slick-active {
        button:before {
          font-weight: @weight-bold;
        }
      }

      button:before {
        font-family: @font-awesome-family;
        font-weight: @weight-bold;
        content: @fa-circle;
        width: auto;
        height: auto;
        line-height: 0;
        position: relative;
        font-size: 15px;
        opacity: 100;
      }

      button {
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
    }
  }
}
