// LAYOUT
@base-size: 16; // base size for font and element sizes
@rem: 1 / @base-size; // FOR MIXIN USE ONLY, convert px to rem (E.g. 12*$rem rem)
@px: @base-size; // FOR MIXIN USE ONLY, convert rem to px (E.g. 12*$px rem)
@container-width: 1170px;

// RESPONSIVE ----------------------
@1280-width: unit((1280 / @base-size), em);
@1200-width: unit((1200 / @base-size), em);
@1199-width: unit((1199 / @base-size), em);
@1024-width: unit((1024 / @base-size), em);
@1023-width: unit((1023 / @base-size), em);
@768-width: unit((768 / @base-size), em);
@767-width: unit((767 / @base-size), em);
@640-width: unit((640 / @base-size), em);
@639-width: unit((639 / @base-size), em);
@479-width: unit((479 / @base-size), em);
@374-width: unit((374 / @base-size), em);

@large-desktop-up: ~"screen and (min-width: @{1280-width})";

@desktop-base-up: ~"screen and (min-width: @{1024-width})";
@desktop-base-down: ~"screen and (max-width: @{1023-width})";

@phone-small-down: ~"screen and (max-width: @{374-width})";
@phonexs-down: ~"screen and (max-width: @{479-width})";

@desktop-up: ~"screen and (min-width: @{1200-width})";
@desktop-down: ~"screen and (max-width: @{1199-width})";

@tablet-up: ~"screen and (min-width: @{768-width})";
@tablet-down: ~"screen and (max-width: @{767-width})";

@phone-up: ~"screen and (min-width:  @{767-width})";
@phone-down: ~"screen and (max-width: @{767-width})";

@mobile-up: ~"screen and (min-width: @{640-width})"; 
@mobile-down: ~"screen and (max-width: @{639-width})";

@mobile-up-and-desktop-down: ~"screen and (min-width: @{640-width}) and (max-width: @{1199-width})";

//Main colors
@color-white: #ffffff;
@color-gray: #e8e8e8;
@color-red: #a32035;
@color-blue: #005eb8;
@color-black: #2e2e2e;

//Secondary Colors
@color-railway-gray: #9D9795; //REMOVE
@color-error-red: #9F2929; //REMOVE

@color-yellow: #ffce00;
@color-dark-yellow: #E5B52D;
@color-dark-blue: #002f5c;
@color-light-blue: #80c1ff;
@color-dark-red: #6b1824;
@color-light-red: #fc8383;

//Transparent Colors
@color-white-75: rgba(255,255,255,0.75);
@color-white-50: rgba(255,255,255,0.5);
@color-white-25: rgba(255,255,255,0.25);
@color-white-90: rgba(255, 255, 255, 0.90);


@color-black-75: rgba(46,46,46,0.75);
@color-black-50: rgba(46,46,46,0.5);
@color-black-25: rgba(46,46,46,0.25);

@text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.50);

@hover-opacity: 0.75;

// TYPOGRAPHY
@font-size-base: (@base-size / 16) * 100%;

@primary-font-family: "noto-sans", sans-serif;
@secondary-font-family: "noto-serif", serif;

@weight-regular: 400;
@weight-semi-bold: 600;
@weight-bold: 700;
@weight-extra-bold: 800;

//Font Awesome
@font-awesome-family: "Font Awesome 5 Pro";
@fa-weight-light: 300;
@fa-weight-solid: 900;
@fa-weight-regular: 400;

//Icons
@fa-circle: "\f111";
@fa-chevron-right: "\f054";
@fa-chevron-left: "\f053";

//Slick
@slick-arrow-weight: 400;

// z-index levels
@page-background: -1; //E.G background effects for the page, life the burst dots
@below-content: 1; //E.G background images, like the logo in about us
@content: 2; //E.G titles over images, like the text for the logo
@above-content: 3; //E.G overlays, like the overlay to darker the logo
@header-content: 9; //HEADER and Fixed FOOTER
@page-disable: 10; //Page disable (above all page content)
@custom-alert: 11; //Custom Alert over the page

// FONT-SIZE
@h1-size: 44px;
@h1-line-height: 110%;
@h1-size-mobile: 38px;
@h1-line-height-mobile: 110%;

@h2-size: 34px;
@h2-line-height: 120%;
@h2-size-mobile: 28px;
@h2-line-height-mobile: 130%;

@h3-size: 28px;
@h3-line-height: 130%;
@h3-size-mobile: 24px;
@h3-line-height-mobile: 130%;

@h4-size: 24px;
@h4-line-height: 130%;
@h4-size-mobile: 20px;
@h4-line-height-mobile: 140%;

@h5-size: 20px;
@h5-line-height: 140%;
@h5-size-mobile: 20px;
@h5-line-height-mobile: 140%;

@disclaimer-size: 14px;
@disclaimer-line-height: 20px;

@p-size: 16px;
@p-line-height: 150%;
@p-size-mobile: 16px;
@p-line-height-mobile: 150%;

@label-heading-font-size: 14px;
@label-heading-line-height: 110%;

@quote-font-size: 24px;
@quote-line-height: 140%;
@quote-font-size-mobile: 24px;
@quote-line-height-mobile: 140%;

@disclaimer-size: 14px;
@disclaimer-line-height: 160%;
@disclaimer-size-mobile: 14px;
@disclaimer-line-height-mobile: 160%;

@section-heading-font-size: 38px;
@section-heading-line-height: 110%;
@section-heading-font-size-mobile: 28px;
@section-heading-line-height-mobile: 120%;

//MIXINS
._disclaimer() {
  font-family: @primary-font-family;
  font-size: @disclaimer-size;
  line-height: @disclaimer-line-height;

  @media @mobile-down {
    font-size: @disclaimer-size-mobile;
    line-height: @disclaimer-line-height-mobile;
  }

  a {
    font-size: @disclaimer-size !important;
    line-height: @disclaimer-line-height !important;
  }
}

._p() {
  font-family: @primary-font-family;
  font-size: @p-size;
  line-height: @p-line-height;  

  @media @mobile-down {
    font-size: @p-size-mobile;
    line-height: @p-line-height-mobile;
  }
}

._h1() {
  font-size: @h1-size;
  line-height: @h1-line-height;
  font-family: @secondary-font-family;
  font-weight: @weight-semi-bold;
  margin-bottom: 15px;

  @media @mobile-down {
    font-size: @h1-size-mobile;
    line-height: @h1-line-height-mobile;
  }
}
._h2() {
  font-size: @h2-size;
  line-height: @h2-line-height;
  font-family: @secondary-font-family;
  font-weight: @weight-semi-bold;
  margin-bottom: 15px;

  @media @mobile-down {
    font-size: @h2-size-mobile;
    line-height: @h2-line-height-mobile;
  }
}
._h3() {
  font-size: @h3-size;
  line-height: @h3-line-height;
  font-family: @secondary-font-family;
  font-weight: @weight-semi-bold;
  margin-bottom: 15px;

  @media @mobile-down {
    font-size: @h3-size-mobile;
    line-height: @h3-line-height-mobile;
  }
}
._h4() {
  font-size: @h4-size;
  line-height: @h4-line-height;
  font-family: @secondary-font-family;
  font-weight: @weight-semi-bold;
  margin-bottom: 15px;

  @media @mobile-down {
    font-size: @h4-size-mobile;
    line-height: @h4-line-height-mobile;
  }
}
._h5() {
  font-size: @h5-size;
  line-height: @h5-line-height;
  font-family: @secondary-font-family;
  font-weight: @weight-regular;
  margin-bottom: 15px;

  @media @mobile-down {
    font-size: @h5-size-mobile;
    line-height: @h5-line-height-mobile;
  }
}

._quote() {
  font-size: @quote-font-size;
  line-height: @quote-line-height;
  font-weight: @weight-regular;
  font-family: @secondary-font-family;

  @media @mobile-down {
    font-size: @quote-font-size-mobile;
    line-height: @quote-line-height-mobile;
  }
}

._section-heading() {
  font-family: @secondary-font-family;
  font-size: @section-heading-font-size;
  font-weight: @weight-bold;
  line-height: @section-heading-line-height;

  @media @mobile-down {
    font-size: @section-heading-font-size-mobile;
    line-height: @section-heading-line-height-mobile;
  }
}

._label_heading() {
  font-size: @label-heading-font-size;
  line-height: @label-heading-line-height;
  font-weight: @weight-bold;
  font-family: @primary-font-family;
  text-transform: uppercase;
}

._ol_ul() {
  ol, ul {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      ._p();
      margin-bottom: 15px;

      a {
        font-weight: bold;
        font-size: inherit;
      }

      p {
          margin-bottom: 0
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  ol {
    list-style-type: decimal;
    ol {
      list-style-type: lower-alpha;
      ol {
        list-style-type: lower-roman;
        ol {
          list-style-type: decimal;
        }
      }
    }
  }

  ul {
    list-style-type: disc;
    color: inherit;
  }

  ul, ol {
    ul, ol {
      li:first-child {
        margin-top: 15px;
      }
      ul, ol {
        li:first-child {
          margin-top: 15px;
        }
        ul, ol {
          li:first-child {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

._special-heading(){
  font-family: @secondary-font-family;
  font-weight: @weight-regular;
  text-transform: uppercase;
}

//Overlays
._overlay() {
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: @color-black;
    opacity: 0.4;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .overlay-0 {
    opacity: 0;
  }

  .overlay-10 {
    opacity: 0.1;
  }

  .overlay-20 {
    opacity: 0.2;
  }

  .overlay-30 {
    opacity: 0.3;
  }

  .overlay-40 {
    opacity: 0.4;
  }

  .overlay-50 {
    opacity: 0.5;
  }

  .overlay-60 {
    opacity: 0.6;
  }

  .overlay-70 {
    opacity: 0.7;
  }

  .overlay-80 {
    opacity: 0.8;
  }

  .overlay-90 {
    opacity: 0.9;
  }
}

.flexbox(@display: flex, @align: normal, @justify: flex-start) {
  display: @display;
  align-items: @align;
  justify-content: @justify;
}

.content-block{
  .content-wrapper{
    .stat-container{
      font-family: @primary-font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 17.6px */
      color:@color-red;
      margin-bottom: 10px;
    }
    .disclaimer{
      .rich-text-content{
        p{
            ._disclaimer();
            line-height: 140%;
        }
      }
    }
  }
  .image-wrapper{
    margin-bottom: 30px;
    .fa{
      color: @color-red;
      font-size: 60px;
      line-height: 100%;
    }
  }
}

section, section div {
  &.Red, &.Red-UMass, &.Blue, &.Blue-UMass, &.Black{
    .content-block {
      .stat-container {
        color: @color-yellow;
      }

      .image-wrapper{
        .fa{
          color: @color-yellow;
        }
      }
    }
  }
}
