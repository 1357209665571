@import './_base.less';
@import (reference) './_colors.less';
@import  './fa-custom.css';
@import './common.less';
@import './font.less';
@import './slick-override.less';
@import './video-overly.less';
@import './rich-text-content.less';

section,
section div {
    &.White {
        ._white();
    }

    &.Gray,
    &.Grey {
        ._gray();
    }

    &.Red,
    &.Red-UMass {
        ._red();
    }

    &.Blue,
    &.Blue-UMass {
        ._blue();
    }

    &.Black {
        ._black();
    }
}
