@import (reference) "./_var.less";

@button-font-family: @primary-font-family;
@button-font-size: 16px;
@button-line-height: 110%;
@button-font-weight: @weight-bold;

._button() {
  display: inline-flex;
  border-width: 2px;
  border-style: solid;
  font-size: @button-font-size;
  line-height: @button-line-height;
  padding: 15px 20px;
  font-family: @button-font-family;
  font-weight: @button-font-weight;
  text-transform: uppercase;
  text-decoration: none;

  &:focus {
    outline-style: solid;
    outline-width: 2px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: none;
  }

  &:focus {
    outline-offset: 2px;
  }
}

._primary_button() {
  ._button();
  border-color: transparent;
}

._primary_button_white_gray_bg() {
  ._primary_button();
  background-color: @color-red;
  color: @color-white;

  span {
    color: @color-white;
  }

  &:focus {
    outline-color: @color-black;
  }

  &:hover {
    background-color: @color-dark-red;
    color: @color-white;

    span {
      color: @color-white;
    }
  }

  &:disabled {
    background-color: @color-black-75;
  }
}

._primary_button_red_blue_black_bg() {
  ._primary_button();
  background-color: @color-yellow;
  color: @color-black;

  span {
    color: @color-black;
  }

  &:focus {
    outline-color: @color-white;
  }

  &:hover {
    background-color: @color-dark-yellow;
    color: @color-black;

    span {
      color: @color-black;
    }
  }

  &:disabled {
    background-color: @color-white-75;
  }
}

._secondary_button() {
  ._button();
  background-color: transparent;
}

._secondary_button_white_gray_bg() {
  ._secondary_button();
  border-color: @color-red;
  color: @color-black;

  &:focus,
  &:focus-visible {
    background-color: @color-red;
    border-color: @color-white;
    outline-color: @color-red;
    color: @color-white;
  }

  &:hover {
    background-color: @color-dark-red;
    color: @color-white;

    span {
      color: @color-white;
    }
  }

  &:disabled {
    background-color: @color-black-75;
  }
}

._secondary_button_red_blue_black_bg() {
  ._secondary_button();
  border-color: @color-yellow;
  color: @color-white;

  &:focus,
  &:focus-visible {
    background-color: @color-yellow;
    border-color: @color-white;
    outline-color: @color-white;
  }

  &:hover {
    background-color: @color-dark-yellow;    
    color: @color-black;
    
    span {
      color: @color-black;
    }
  }

  &:disabled {
    background-color: @color-white-75;
  }
}

//Solid Color Button
.solid-primary-blue-btn() {
  ._primary_button();
  background-color: @color-blue;
  color: @color-white;

  span {
    color: @color-white;
  }

  &:focus {
    outline-color: @color-black;
  }

  &:hover {
    background-color: @color-dark-blue;
    color: @color-white;

    span {
      color: @color-white;
    }
  }

  &:disabled {
    background-color: @color-black-75;
  }
}
