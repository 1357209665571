@import (reference) './_var.less';

.video-player {
    max-width: 100%;			
    object-fit: cover;
    position: relative;
}

.videoOverlay {
	position: absolute;
	display: block;
	font-size: 80px;
	text-align: center;
	padding: 0;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: @content;
	text-shadow:  0px 8px 12px rgba(0, 0, 0, 0.50);

	&:before {
		color: white;
		font-family: @font-awesome-family;
		content: '\f330';
		font-weight: 400;
		display: block;
        line-height: 100%;
		font-size: 60px;
	}

	@media screen and (max-width: 1199px) {
		font-size: 71px;
	}
}