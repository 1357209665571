@import (reference) "./_var.less";

// ----------------------------
// BASE - elements that don't have classes or IDs
// ----------------------------

html {
  margin: 0;
  padding: 0;
  font-size: @font-size-base;
  height: initial !important;
  min-height: 100%;
  // momentum scrolling for iOS
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  // proper font weight and smoothing rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // ie scroll bar overlay
  -ms-overflow-style: scrollbar;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 200dpi),
    only screen and (min-resolution: 1.25dppx) {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

main {
  display: block;
}

p,
li,
a {
  font-family: @primary-font-family;
  font-weight: @weight-regular;
  margin: 0;
}

body {
  font-size: @font-size-base;
  font-family: @primary-font-family;
  line-height: 1.45;
  position: relative;
  font-style: normal;
  height: 100%;
  width: 100%;
}

p {
  margin-top: 0;
  font-size: 1em;
  color: @color-black;
  font-weight: @weight-regular;

  a {
    color: currentColor;
    text-decoration: none;

    &:hover {
      color: currentColor;
    }

    &:focus {
      color: currentColor;
      outline: 2px solid currentColor;
    }
  }
}

ul,
ol {
  color: @color-black;
  padding: 0;
  margin: 0;
}

ul li,
ol li {
  padding-left: 5px;
  position: relative;
}

ul li.accordion-item,
ul li.save-tab,
ul.slick-dots li,
ul.menu li,
ul.breadcrumbs li {
  &::before {
    content: none;
  }
}

iframe {
  display: block;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;  
  border-width: 1px;
  border-style: solid;
  padding-right: 45px;
  padding-left: 15px;
  
  &:focus {
    border-width: 2px;
    border-style: solid;
  }

  &::-ms-expand {
    display: none;
  }
}

input {
  &[type="text"] {
    border: 1px solid @color-railway-gray;
    
    &:focus {
      border: 2px solid @color-black;
    }
  }
}

figcaption {
  color: @color-black;
}

sub,
sup {
  font-size: 65%;
}

form {
  margin-bottom: 0;
}

.clear:before,
.clear:after {
  content: "";
  display: table;
}

.clear:after {
  clear: both;
}

.clear {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
}

.firefox input:required {
  box-shadow: none !important;
}

.lity-wrap {
  z-index: 99999;
}

// Override user agent style for disabled select elements
select {
  &:disabled {
    opacity: 1;
    cursor: not-allowed !important;
  }
}
